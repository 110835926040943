<template>
    <div class="">
        <UploadBatchCard @update="getPage" />
        <h2 class="mt-2 mb-2">Recently Added</h2>
        <div id="data-list-list-view" class="data-list-container" v-if="logs">
            <BatchLogTable
                :data="logs"
                :itemsPerPage="itemsPerPage"
                @itemsPerPage="setItemsPerPage"
                @search="search"
                @filter="setFilter"
                @deleteSingle="deleteConfirm"
                @edit="setEdit"
                @new="setNew"
            ></BatchLogTable>
            <div class="paginator-container">
                <vs-pagination
                    description
                    goto
                    descriptionTitle="Logs"
                    :sizeArray="logs.count"
                    :description-items="descriptionItems"
                    :total="logs.number_of_pages"
                    :max-items="itemsPerPage"
                    :max="itemsPerPage"
                    v-model="pageNumberComputed"
                    @changeMaxItems="setItemsPerPage"
                ></vs-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import UploadBatchCard from '@/views/main/SendBatch/Cards/UploadBatch'
import BatchLogTable from './Table'
import { http } from '@/services'

export default {
    name: 'SendBatch',
    components: {
        UploadBatchCard,
        BatchLogTable,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'sendBatch',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        pageNumberComputed: {
            set(value) {
                this.pageNumber = value
                this.getPage()
            },
            get() {
                return this.pageNumber
            },
        },
    },
    data() {
        return {
            logs: null,
            pageNumber: 1,
            itemsPerPage: 5,
            selected: null,
            queriedItems: 20,
            searchQuery: null,
            filter: 'all',
            descriptionItems: [5, 10, 20],
            selectedCode: null,
            show: {
                createEditModal: false,
                info1: true,
            },
        }
    },
    methods: {
        setItemsPerPage(value) {
            this.itemsPerPage = this.descriptionItems[value]
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        setView(value) {
            this.searchQuery = null
            this.view = value
            this.getPage()
        },
        getPage() {
            http.get('batch_logs', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                },
            })
                .then((response) => {
                    this.logs = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.searchQuery = value
            this.getPage()
        },
        setFilter(value) {
            this.filter = value
            this.getPage()
        },
        deleteConfirm(code) {
            this.selectedCode = code
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Access Code`,
                text: `${this.selectedCode.code}`,
                accept: this.deleteCode,
                acceptText: 'Delete',
            })
        },
        deleteCode() {
            http.delete('quick_code_detail', {
                params: { id: this.selectedCode.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code Deleted',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.getPage()
                    this.selectedCode = null
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.selectedCode = null
                })
        },
        setEdit(value) {
            this.selectedCode = value
            this.show.createEditModal = true
        },
        setNew() {
            this.selectedCode = null
            this.show.createEditModal = true
        },
        closeCreateEditModal() {
            this.show.createEditModal = false
            this.selectedCode = null
        },
    },
}
</script>
