<template>
    <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 xl:1/2 mb-base">
            <vx-card
                :showOverflow="true"
                class="vs-con-loading__container"
                id="loading-container"
            >
                <div
                    slot="no-body"
                    :style="{
                        background: `rgba(var(--vs-primary),.7)`,
                    }"
                >
                    <div class="vx-row no-gutter justify-center p-12">
                        <div class="text-center">
                            <h1 class="text-center font-bold text-white">
                                <feather-icon
                                    :icon="'SendIcon'"
                                    class="p-3 inline-flex rounded-full"
                                    :class="[`text-white`, { 'mb-4': true }]"
                                    :style="{
                                        background: `rgba(var(--vs-primary),.4)`,
                                    }"
                                ></feather-icon>
                                Send Batch
                            </h1>
                        </div>
                    </div>

                    <div class="vx-row no-gutter w-full">
                        <div class="vx-col w-full d-theme-dark-bg p-12">
                            <vs-alert
                                active="true"
                                color="primary"
                                class="mb-3"
                                icon="icon-info"
                                icon-pack="feather"
                            >
                                You can upload and send a batch of clients that
                                are listed within a CSV file. View the example
                                file to get started. If you do not send
                                invitations immediately then they will be sent
                                automatically on a Saturday morning
                            </vs-alert>
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <p class="text-primary text-right mb-4">
                                        <a
                                            href="https://mylastwill.s3.amazonaws.com/static/csv/example-data.csv"
                                            download
                                            class="text-small f-right text-primary"
                                            >Download example CSV file</a
                                        >
                                    </p>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full sm:w-1/2">
                                    <div class="vx-row">
                                        <div class="vx-col w-full mb-6">
                                            <vs-checkbox
                                                v-model="form.send"
                                                color="primary"
                                                >Send invitations
                                                immediately</vs-checkbox
                                            >
                                        </div>
                                        <div class="vx-col w-full mb-6">
                                            <vs-input
                                                v-model="form.discount"
                                                label="Discount Percentage"
                                                type="number"
                                                class="w-full"
                                                color="primary"
                                                :danger-text="errors.discount"
                                                :danger="
                                                    errors.discount !== null
                                                "
                                                :description-text="
                                                    discountHelpText
                                                "
                                            />
                                        </div>
                                        <div class="vx-col w-full">
                                            <label class="vs-input--label"
                                                >Description</label
                                            >
                                            <vs-textarea
                                                v-model="form.description"
                                                class="w-full"
                                            ></vs-textarea>
                                        </div>

                                        <div class="vx-col w-full mb-4">
                                            <ImportExcel
                                                :onSuccess="loadDataInTable"
                                                :fileChange="fileChange"
                                                :file="form.file"
                                                :error="errors.file"
                                            />
                                        </div>
                                        <div class="vx-col w-full">
                                            <vs-button
                                                @click="upload"
                                                :disabled="!validatedForm"
                                                icon-pack="feather"
                                                icon="icon-upload"
                                                color="primary"
                                                class="mr-3"
                                            >
                                                {{ sendText }}
                                            </vs-button>
                                        </div>
                                    </div>
                                </div>
                                <VuePerfectScrollbar
                                    ref="chatLogPS"
                                    class="vs-col w-full sm:w-1/2 mb-6 p-6 max"
                                    :settings="scrollSettings"
                                    :key="$vs.rtl"
                                >
                                    <vs-alert
                                        v-if="
                                            response &&
                                            response.clients_added !== null
                                        "
                                        class="mb-4 text-primary"
                                        color="primary"
                                    >
                                        {{ response.clients_added }} Clients
                                        Added
                                    </vs-alert>

                                    <div v-if="response && response.add_errors">
                                        <h5 class="mb-2">Errors</h5>
                                        <p
                                            v-for="(error,
                                            index) in response.add_errors"
                                            :key="index"
                                            class="text-secondary"
                                        >
                                            {{ error }}
                                        </p>
                                    </div>
                                </VuePerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        <div class="vx-col w-full lg:w-1/2 xl:1/2 mb-base">
            <vx-card
                :title="sheetName"
                v-if="tableData.length && header.length"
            >
                <vs-table pagination :max-items="10" :data="tableData">
                    <template slot="thead">
                        <vs-th
                            :sort-key="heading"
                            v-for="heading in header"
                            :key="heading"
                            >{{ heading }}</vs-th
                        >
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                        >
                            <vs-td
                                :data="col"
                                v-for="(col, indexcol) in data[indextr]"
                                :key="indexcol + col"
                            >
                                {{ col }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'
import { prices } from '@/mixins/prices'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ImportExcel from '@/components/excel/ImportExcel'

import { http } from '@/services'

export default {
    name: 'UploadBatchCard',
    mixins: [textFormat, prices],
    components: {
        VuePerfectScrollbar,
        ImportExcel,
    },
    computed: {
        subTitle() {
            return 'Broker batch'
        },
        validatedForm() {
            return !!(this.form.description && this.form.file)
        },
        sendText() {
            if (this.form.send) return 'Upload and Send'
            return 'Upload'
        },
        discountHelpText() {
            if (this.form.discount) {
                return `${
                    this.form.discount
                }% discount applied. £${this.costSingle(
                    this.form.discount
                )} Single or £${this.jointPrice(this.form.discount)} for mirror`
            }
            return 'The % discount to be given to the client'
        },
    },
    data() {
        return {
            form: {
                file: null,
                description: null,
                send: true,
                discount: null,
            },
            response: null,
            scrollSettings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
            tableData: [],
            header: [],
            sheetName: '',
            errors: {
                discount: null,
                description: null,
                file: null,
            },
        }
    },
    methods: {
        upload() {
            if (this.validatedForm) {
                this.clearErrors()
                this.$vs.loading({
                    container: '#loading-container',
                    scale: 0.6,
                })

                var formData = new FormData()
                formData.append('description', this.form.description)
                formData.append('file', this.form.file)
                formData.append('discount', this.form.discount)
                formData.append('send', this.form.send)

                http.post('batch_upload', formData)
                    .then((response) => {
                        this.$vs.loading.close(
                            '#loading-container > .con-vs-loading'
                        )
                        this.$vs.notify({
                            title: 'Success',
                            text: 'File uploaded',
                            color: 'success',
                            icon: 'feather',
                            iconPack: 'icon-save',
                            position: 'top-center',
                        })
                        this.$emit('update')
                        this.form.description = null
                        this.form.file = null
                        this.form.discount = null
                        this.tableData = []
                        this.response = response.data
                    })
                    .catch((error) => {
                        this.handleErrors(error.response.data)
                        this.$vs.loading.close(
                            '#loading-container > .con-vs-loading'
                        )
                    })
            }
        },
        handleErrors(errors) {
            if (errors.description)
                this.errors.description = errors.description[0]
            if (errors.file) this.errors.file = errors.file[0]
            if (errors.discount) this.errors.discount = errors.discount[0]
        },
        clearErrors(errors) {
            this.errors.discount = null
            this.errors.description = null
            this.errors.file = null
        },
        loadDataInTable({ results, header, meta }) {
            this.header = header
            this.tableData = results
            this.sheetName = meta.sheetName
        },
        fileChange(file) {
            this.form.file = file
        },
        setBroker(value) {
            if (value) this.form.broker = value.value
            else this.form.broker = null
        },
    },
}
</script>

<style scoped>
.max {
    max-height: 500px;
}
</style>
