<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        :data="logs"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Date Sent</vs-th>
            <vs-th>Description</vs-th>
            <vs-th>Clients Added</vs-th>
            <vs-th>Clients sent codes</vs-th>
            <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="log" :key="index" v-for="(log, index) in data">
                    <vs-td>
                        {{ formatDateAndTime(log.date_added) }}
                    </vs-td>

                    <vs-td>
                        <p>
                            {{ log.description }}
                        </p>
                    </vs-td>
                    <vs-td>
                        {{ log.clients_count }}
                    </vs-td>
                    <vs-td>
                        {{ log.clients_sent_count }}
                    </vs-td>

                    <vs-td> </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'BatchLogTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        logs() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
        progressStatus(log) {
            if (log.client.code_completed) return 'Will Created'
            if (log.client.code_used) return 'Account Created'
            if (log.client.code_clicked) return 'Code Clicked'
            if (log.client.code_sent) return 'Code Sent'
        },
    },
}
</script>
